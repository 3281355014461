import React from "react";
import styled from "styled-components";
import Hyphenated from "react-hyphen";
import { StaticImage } from "gatsby-plugin-image";
import pl from "hyphenated-pl";
import Background from "../assets/images/backgroundPurple1.svg";
import * as styles from "./Hero.module.scss";
import Form from "../components/Form/Form";
import { HeroText } from "../data/TextData";
import Clock from "../assets/images/Hero/clock.svg";
import Question from "../assets/images/Hero/question.svg";
import Logo from "../assets/images/Hero/logo.svg";

function Hero() {
  return (
    <>
      <HeroContainer>
        <LogoWrapper>
          <Logo className={styles.logo}/>
        </LogoWrapper>
          <Background className={styles.background} />
        <ImageContainer>
          <StaticImage
            src="../assets/images/Hero/windows.jpg"
            alt="windows"
            placeholder="blurred"
        
          />
        </ImageContainer>

        <ColumnWrapper>
          <Hyphenated language={pl}>
            <Text>
              <Question className={styles.question} />
              <Header>{HeroText.doYouKnow.title}</Header>
              <Paragraph>{HeroText.doYouKnow.desc}</Paragraph>
            </Text>
            <HigherText>
              <Clock className={styles.clock} />
              <Header>{HeroText.dontWasteTime.title}</Header>
              <Paragraph>{HeroText.dontWasteTime.desc}</Paragraph>
            </HigherText>
          </Hyphenated>
          <FormWrapper id="form">
            <Form />
          </FormWrapper>
        </ColumnWrapper>
      </HeroContainer>
    </>
  );
}

export default Hero;


const LogoWrapper = styled.div`
  width: 440px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 100;
  @media screen and (max-width: 1520px) {
    width: 410px;
  }


  @media screen and (max-width: 1200px) {
    width: 410px;
    left:0;
    right:0;
    margin:0 auto;

  }
  @media screen and (max-width: 420px){
    width: 90%;
}
`;

const ImageContainer = styled.div`
  position: absolute;
  width: 100%;
  padding-top: -300px;
  height: 500px;
  top: 0;
  left: 0;
`;

const HeroContainer = styled.div`
  justify-content: left;
  width: 100%;
  z-index: 1;
  background: linear-gradient(to left, #0d3bbc, #1fa4df);



`;


const ColumnWrapper = styled.div`
  width: 100%;
  position: relative;
  bottom: 0;
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  align-content: space-around;
  padding-top: 145px;
  @media screen and (max-width: 800px) {
    flex-direction: column-reverse;
    height: auto;
    align-items: center;
    padding-top: 120px;
    /* top: setting to adjust  */
  }
  @media screen and (max-width: 800px) {
    padding-top: 140px;
  }
  @media screen and (max-width: 400px) {
    /* top: setting to adjust  */
  }
`;

const Text = styled.div`
  position: relative;
  width: 30%;
  padding: 35px;
  margin-bottom: 20px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  z-index: 2;
  line-height: 1.3;
  text-align: left;
  font-size: clamp(1.2rem, 1.6rem, 2rem);
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  @media screen and (max-width: 1200px) {
    display: none;
  }
`;

const HigherText = styled(Text)`
  @media screen and (max-width: 1200px) {
    display: block;
    width: 45%;
  }
  @media screen and (max-width: 800px) {
    margin-top: 35px;
    width: 90%;
  }
`;

export const FormWrapper = styled(Text)`
  height: auto;
  width: 35%;
  z-index: 2;
  @media screen and (max-width: 1200px) {
    display: block;
    width: 45%;
  }
  @media screen and (max-width: 800px) {
    width: 90%;
    padding: 15px;
  }
`;

export const Header = styled.h3`
  color: white;
  opacity: 1;
  font-size: clamp(1.4rem, 2.3rem, 3.4rem);
  margin-bottom: 20px;
  text-shadow: 0.1em 0.1em 0 rgba(0, 0, 0, 0.2);

  @media screen and (max-width: 800px) {
    text-align: center;
  }
  @media screen and (max-width: 500px) {
    font-size: 2rem;
  }
`;
const Paragraph = styled.p`
  font-size: clamp(1.1rem, 1.3rem, 1.9rem);
  color: white;
`;
