import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { BiShowAlt } from "react-icons/bi";
import { BsFillCheckCircleFill } from "react-icons/bs";
import styled from "styled-components";
import { ModalContent } from "./ModalForm";
import CloseButton from "react-bootstrap/CloseButton";
function ModalBootstrap({ show, handleClose }) {
  return (
    <>
      <Modal show={show} onHide={handleClose} animation={false}>
        <ModalWrapper>
          <ModalHeader>
            <Title>Formularz został wysłany poprawnie</Title>
            <CloseButton variant="white" onClick={handleClose} />
          </ModalHeader>
          <Modal.Body style={{ color: "black" }}>
            <Wrapper>
              <BsFillCheckCircleFill
                style={{
                  color: "#00d504",
                  fontSize: "3.2rem",
                  marginRight: "10px",
                  filter: "drop-shadow(3px 5px 2px rgb(0 0 0 / 0.2))",
                }}
              />
              <Text> Wkrótce przedstawimy Ci ofertę finansową</Text>
            </Wrapper>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="warning" onClick={handleClose}>
              Zamknij
            </Button>
          </Modal.Footer>
        </ModalWrapper>
      </Modal>
    </>
  );
}

export default ModalBootstrap;

const ModalHeader = styled(Modal.Header)`
  color: #9a9797;
  background: rgba(255, 255, 255, 0.2);
  .close {
    color: white;
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ModalWrapper = styled(ModalContent)`
  color: white;
  border: 5px solid white;
`;

const Text = styled.span`
  font-size: 1.3rem;
  color: white;
`;

const Title = styled.span`
  font-size: 1.6rem;
  color: #eeeeee;
`;
