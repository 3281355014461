import React from "react";
import styled from "styled-components";

function SprzedamSzkodeText() {
  return <Title to="/">WINDOOR</Title>;
}

export default SprzedamSzkodeText;

export const Title = styled.h1`
  position: absolute;
  font-display: optional;
  letter-spacing: 1px;
  font-family: "Myriad Pro" sans-serif;
  font-weight: 500;
  color: white;
  font-size: clamp(2.4rem, 18vw, 4.5rem);
  text-align: center;
  z-index: 11;

  @media screen and (max-width: 770px) {
    font-size: 3.3rem;
  }
  @media screen and (max-width: 550px) {
    font-size: 2.8rem;
  }
  @media screen and (max-width: 460px) {
    font-size: 2.6rem;
  }
  @media screen and (max-width: 425px) {
    font-size: 2.2rem;
  }
  @media screen and (max-width: 370px) {
    font-size: 1.9rem;
  }
`;
