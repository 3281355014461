import React from "react";
import TransparentContainer from "./TransparentContainer/TransparentContainer";
import { myObjects, offertArray } from "../data/TextData";
import styled from "styled-components";
function Offer() {
  const { title, image } = myObjects.offer;

  return (
    <TransparentContainer id="offer" title={title} image={image}>
      {offertArray.map((el) => (
        <Description>{el}</Description>
      ))}
      <Signature>Z poważaniem, Zespół Windoor</Signature>
    </TransparentContainer>
  );
}

export default Offer;

const Description = styled.p`
  font-size: clamp(1.1rem, 1.5rem, 1.9rem);
  width: 95%;
  line-height: 1.3;
  margin: auto;
  margin-bottom: 30px;
  text-align: left;
  @media screen and (min-width: 1200px) {
    width: 85%;
  }
`;

const Signature = styled(Description)`
  text-align: right;
  font-weight: 300;
  font-size: 1.5rem;
  font-style: italic;
`;
