import React, { useState } from "react";
import axios from "axios";
import styled from "styled-components";
import Hyphenated from "react-hyphen";
import pl from "hyphenated-pl";
import FormS from "react-bootstrap/Form";
import ReactTooltip from "react-tooltip";
import { BiMailSend } from "react-icons/bi";
import { FormErrors } from "./FormErrors";
import Input from "../Input/Input";
import Button from "../Button/Button";
import "./FormErrors.module.scss";
import ModalBootstrap from "../ModalBootstrap";


const GETFORM_FORM_ENDPOINT =
  "https://getform.io/f/7c978897-7804-4226-9af2-e8f96bc3c74e";

const regexpPhonePL =
  /^(?:(?:(?:\+|00)?48)|(?:\(\+?48\)))?(?:1[2-8]|2[2-69]|3[2-49]|4[1-8]|5[0-9]|6[0-35-9]|[7-8][1-9]|9[145])\d{7}$/;

function Form() {
  // ************************ STATES START **********************//

  const [formStatus, setFormStatus] = useState(false);

  // ******************* check if field was blurred ******************/
  const [blured, setBlured] = useState({
    name: false,
    phone: false,
    email: false,
  });

  // ******************* outline color for input fields ******************/
  const [outline, setOutline] = useState({
    name: "none",
    phone: "none",
    email: "none",
    formErrors: {
      name: "",
      phone: "",
      email: "",
    },
  });

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // ******************* input fields initial value ******************/
  const [query, setQuery] = useState({
    name: "",
    phone: "",
    email: "",
  });
  // ******************* errors and validation field values ******************/
  const [validate, setValidate] = useState({
    nameValid: false,
    phoneValid: false,
    emailValid: false,
    formValid: false,
  });

  // ************************ STATES END **********************//

  // ************ Handle Input Chage *******************/

  const handleChange = () => (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setQuery((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    setValidate((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    validateField(name, value);
  };

  // ************* Handle Input Number  *******************/
  const handleInputNumber = () => (e) => {
    const result = e.target.value.replace(/\D/g, "");
    const name = e.target.name;
    const value = e.target.value;
    setQuery((prevState) => ({
      ...prevState,
      [name]: result,
    }));
    setValidate((prevState) => ({
      ...prevState,
      [name]: result,
    }));
    validateField(name, value);
  };

  // one file attachment function
  const handleFileChange = () => (e) => {
    const targetFile = e.target.files[0];
    setQuery((prevState) => ({
      ...prevState,
      files: targetFile,
    }));
  };

  // ************* Handle Input Blur *******************/
  const handleBlur = () => (e) => {
    handleChange();
    const name = e.target.name;
    setBlured((prevState) => ({
      ...prevState,
      [name]: true,
    }));
  };

  // ************* Handle Color Input Validation *******************/
  // ********* GREEN ////////////
  const greenOutline = (name) => {
    const greenCustom = "#00bc00";
    setOutline((prevState) => ({
      ...prevState,
      [name]: greenCustom,
    }));
  };
  // ********* RED ////////////
  const redOutline = (name, errorText) => {
    const redCustom = "#f32525";
    setOutline((prevState) => ({
      ...prevState,
      [name]: redCustom,
      formErrors: {
        ...prevState.formErrors,
        [name]: errorText,
      },
    }));
  };

  // ******************* handle funcion before and afrer form submitting ******************/
  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    Object.entries(query).forEach(([key, value]) => {
      formData.append(key, value);
    });

    handleShow();
    axios
      .post(GETFORM_FORM_ENDPOINT, formData, {
        headers: { Accept: "application/json" },
      })
      .then(function (response) {
        setFormStatus(true);

        setValidate((prevState) => ({
          ...prevState,
          formValid: false,
        }));
        setQuery({
          name: "",
          phone: "",
          email: "",
        });

        setOutline((prevState) => ({
          ...prevState,
          name: "none",
          phone: "none",
          email: "none",
        }));
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // ******************* Function wchich validate input fields ******************/
  function validateField(fieldName, value) {
    let fieldValidationErrors = outline.formErrors;
    let nameValid = validate.nameValid;
    let phoneValid = validate.phoneValid;
    let emailValid = validate.emailValid;
    switch (fieldName) {
      case "name":
        nameValid = value.length >= 3;
        fieldValidationErrors.name = nameValid
          ? greenOutline(fieldName)
          : redOutline(fieldName, "Imię jest zbyt krótkie");
        break;
      case "phone":
        phoneValid = value.match(regexpPhonePL);
        fieldValidationErrors.phone = phoneValid
          ? greenOutline(fieldName)
          : redOutline(fieldName, "Numer telefonu nie jest poprawny");
        break;
      case "email":
        emailValid = value.match(
          /^(([^<>()[\],;:\s@"]+([^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\],;:\s@"]+\.)+[^<>()[\],;:\s@"]{2,})$/i
        );

        fieldValidationErrors.email = emailValid
          ? greenOutline(fieldName)
          : redOutline(fieldName, "Email jest nieprawidłowy");
        break;
      default:
        break;
    }
    // ******************* Check if input field is empty ******************/

    if (!value) {
      fieldValidationErrors[fieldName] = value.length === 0 && "";
    }

    setValidate((prevState) => ({
      ...prevState,
      nameValid: nameValid,
      phoneValid: phoneValid,
      emailValid: emailValid,
      formValid: nameValid && phoneValid && emailValid,
    }));
  }

  return (
    <>
      <MainForm
        acceptCharset="UTF-8"
        method="POST"
        enctype="multipart/form-data"
        id="gatsbyForm"
        onSubmit={handleSubmit}
      >
        <Hyphenated language={pl}>
          <Header>Umów się na bezpłatną wycenę</Header>
          <UnderHeader>
            Jeśli chcesz abyśmy przyjechali do Ciebie i zrobili darmową wycenę
            bądź podali mniej więcej koszty realizacji wyślij do nas formularz
          </UnderHeader>
          <Input
            placeholder="Imię*"
            type="text"
            name="name"
            outline={outline.name}
            onChange={handleChange()}
            onBlur={handleBlur()}
            value={query.name}
          />
          {blured.name && query.name.length > 0 ? (
            <FormErrors formErrors={outline.formErrors.name} />
          ) : (
            <FormErrors formErrors={""} />
          )}
          <Input
            placeholder="Telefon*"
            type="text"
            name="phone"
            maxLength={9}
            onBlur={handleBlur()}
            outline={outline.phone}
            onChange={handleInputNumber()}
            value={query.phone}
          />
          {blured.phone && query.phone.length > 0 ? (
            <FormErrors formErrors={outline.formErrors.phone} />
          ) : (
            <FormErrors formErrors={""} />
          )}
          <Input
            placeholder="Email*"
            type="email"
            name="email"
            outline={outline.email}
            onBlur={handleBlur()}
            onChange={handleChange()}
            value={query.email}
          />
          {blured.email ? (
            <FormErrors formErrors={outline.formErrors.email} />
          ) : (
            <FormErrors formErrors={""} />
          )}
          {/* /* ********************** File Input/Upload ************************* */}
          <FormS.Group controlId="formFile" className="mb-3">
            <InputFile
              type="file"
              size="lg"
              onChange={handleFileChange()}
              style={{
                borderRadius: "30px",
              }}
            />
            <FormLabel>
              <div>* </div>
              <div>załącz dokument bądź zdjęcie do wyceny</div>
            </FormLabel>
          </FormS.Group>

          {/* /* ************ Regulation and terms accept label ***************** */}
          {formStatus && (
            <>
              <ModalBootstrap show={show} handleClose={handleClose} />
            </>
          )}
          <Span data-tip="Uzupełnij poprawnie dane" data-tip-disable={false}>
            <Button type="submit" disabled={!validate.formValid}>
              UMÓW WIZYTĘ
              <BiMailSend style={{ marginLeft: "10px", fontSize: "40px" }} />
            </Button>
          </Span>
          {!validate.formValid && <ReactTooltip />}
        </Hyphenated>
      </MainForm>
    </>
  );
}

export default Form;

const InputFile = styled(FormS.Control)`
  @media screen and (max-width: 500px) {
    font-size: 1rem;
  }
`;

const FormLabel = styled(FormS.Label)`
  margin-left: 10px;
  margin-top: 5px;
  font-size: 1.2rem;
  display: flex;
  div:first-child {
    margin-right: 10px;
  }

  @media screen and (max-width: 500px) {
    font-size: 1.3rem;
  }
`;

const Span = styled.span`
  width: 100%;
`;

const MainForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: auto;
  align-content: center;
  align-items: center;
  color: white;
  @media screen and (max-width: 630px) {
    width: 98%;
  }
  @media screen and (min-width: 631px) and (max-width: 800px) {
    width: 80%;
  }
  @media screen and (min-width: 801px) and (max-width: 1050px) {
    width: 100%;
  }
`;

const Header = styled.h2`
  color: white;
  opacity: 1;
  font-size: clamp(1.1rem, 2.1rem, 2.8rem);
  margin-bottom: 10px;
  text-shadow: 0.1em 0.1em 0 rgba(0, 0, 0, 0.1);

  @media screen and (max-width: 1650px) {
    font-size: 1.8rem;
    text-align: center;
  }
  @media screen and (max-width: 800px) {
    text-align: center;
    font-size: 1.6rem;
  }
`;

const UnderHeader = styled.h3`
  font-size: clamp(0.6rem, 1.2rem, 1.8rem);
  font-weight: 400;
  margin-bottom: 15px;
  text-align: center;
  color: #d5d5d5;
  line-height: 1.1;
  @media screen and (max-width: 800px) {
    font-size: 1.2rem;
  }
`;
