import React from "react";

import Kontakt from "../assets/images/HowItWorks/kontakt.svg";
import Kosztorys from "../assets/images/HowItWorks/kosztorys.svg";
import Umowa from "../assets/images/HowItWorks/umowa.svg";

export const AboutCompanyArr = [
    {
        title: "1. Kontakt z nami",
        image: <Kontakt />,
      },

    {
      title: "2. Wycena zlecenia",
      image: <Kosztorys />,
    },

    {
      title: "3. Dogadanie warunków",
      image: <Umowa />,
    },

  ];
  