import React from 'react';
import styled from 'styled-components';

function InputEl({
  placeholder,
  type,
  name,
  onChange,
  value,
  required,
  maxLength,
  onKeyPress,
  onBlur,
  correct,
  outline,
}) {
  return (
    <>
      <InputWhite
        placeholder={placeholder}
        type={type}
        name={name}
        onChange={onChange}
        onKeyPress={onKeyPress}
        value={value}
        onBlur={onBlur}
        maxLength={maxLength}
        required={required}
        outline={outline}
      />
    </>
  );
}

export default InputEl;

const InputWhite = styled.input.attrs((props) => ({
  className: props.className,
}))`
  outline: 4px solid ${(props) => (props.outline ? props.outline : 'none')};
  font-size: 1.7rem;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(16, 37, 14, 0.32) 0px 2px 16px 0px;

  width: 100%;
  margin: 2px 0;
  color: rgba(0, 0, 0, 0.6);
  background: white;
  border-radius: 30px;
  border: none;
  height: 60px;
  padding: 15px;
  padding-left: 25px;

  font-weight: 500;
  ::placeholder {
    color: rgba(0, 0, 0, 0.4);
  }
  :focus {
    outline: none;
  }

  @media screen and (max-width: 1580px) {
    height: 50px;
    font-size: 1.3rem;
  }
`;
