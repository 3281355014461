import React from "react";
import WindowPeace from "../assets/images/AboutCompany/windowPeace.svg";
import Megaphone from "../assets/images/megaphone.svg";

import * as styles from "../styles/Images.module.scss";

export const HeroText = {
  doYouKnow: {
    title: `Czy wiesz że...?`,
    desc: `Nieszczelne okna i drzwi mogą prowadzić do znacznej utraty ciepła w budynku. Według statystyk,
    okna i drzwi stanowią około 25-30% całkowitej powierzchni przegród zewnętrznych budynków i odpowiadają
     za około 10-25% strat ciepła w budynkach mieszkalnych. 
   `,
  },
  dontWasteTime: {
    title: `Nie trać czasu...`,
    desc: `Zainwestuj w drzwi i okna, które pozwolą Ci zaoszczędzić znaczna sumę pieniędzy. W przypadku budynków o wysokim współczynniku 
      przenikania ciepła, takich jak starsze budynki lub budynki o niskiej izolacyjności cieplnej, straty
       ciepła przez nieszczelne okna i drzwi mogą być jeszcze większe od 10-25%, które występują standarowo w budynkach. Wymiana starych, nieszczelnych okien 
       i drzwi na nowe, szczelne modele może znacznie zmniejszyć straty ciepła i zwiększyć efektywność energetyczną budynku.`,
  },
};

export const myObjects = {
  offer: {
    image: <Megaphone className={styles.ImageRight} />,
    title: "Oferta",
  },
  aboutCompany: {
    image: <WindowPeace className={styles.ImageLeft} />,
    title: "O Firmie",
    desc: `Nasza firma Windoor zajmuję się sprzedażą oraz montażem okien. Posiadamy 12 letnie doświadczenie na rynku, dzięki czemu jesteśmy w stanie zapewnić
     wysokiej jakości usługi montażu.
     W swojej ofercie posiadamy okna i drzwi bezpośrednio od producenta, co pozwala nam zapewnić najniższe ceny na rynku. Oprócz sprzedaży i montażu, 
     zajmujemy się także doradztwem w zakresie wyboru odpowiednich okien i drzwi dla naszych klientów, tak aby miec pewność, że otrzymają Państwo produkty spełniające
      najwyższe oczekiwania. Jesteśmy pewni, że dzięki naszemu doświadczeniu i szerokiej ofercie, będziemy w stanie zaspokoić potrzeby każdego klienta.`,
  },

  stats: {
    title: "Statystyki..",
  },
};

export const offertArray = [
  `        Nasza firma Windoor z wieloletnim doświadczeniem oferuje Państwu montaż
oraz sprzedaż najwyższej jakości okien. W naszej ofercie znajdą Państwo
szeroki wybór okien od sprawdzonych producentów, które zapewnią
skuteczną izolację termiczną oraz akustyczną.`,
  `        Nasz zespół to profesjonaliści z wieloletnim doświadczeniem w branży
stolarki okiennej. Zajmujemy się montażem okien zarówno w budynkach
mieszkalnych, jak i przemysłowych. Gwarantujemy szybki oraz sprawny
montaż, a także pełne doradztwo przy wyborze odpowiednich okien i drzwi do
Państwa budynku.`,
  `      Zapewniamy darmową wycenę projektu zarówno online, jak i na miejscu.
Jesteśmy elastyczni i dokładamy wszelkich starań, aby dostosować się do
Państwa potrzeb i oczekiwań. Zachęcamy do zapoznania się z naszą ofertą
oraz do kontaktu z nami w celu uzyskania szczegółowych informacji.`,
];
