import React from "react";
import styled from "styled-components";
import { footerData } from "../data/FooterData";
import SocialIcons from "./SocialIcons/SocialIcons";

function Footer() {
  return (
    <MainWrapper id="contact">
      <Grid>
        {footerData.map((element, index) => (
          <React.Fragment key={index}>
            <Title style={{ gridArea: `title-${index}` }}>
              {element.title}
            </Title>
            <Yin style={{ gridArea: `yin-${index}` }}>
              <IconWrapper>{element.icon}</IconWrapper>
              <RightSide>
                {element.yin.map((el, indexX) => (
                  <CustomWrapper key={indexX}>{el}</CustomWrapper>
                ))}
              </RightSide>
            </Yin>

            {element.yang && (
              <Yang style={{ gridArea: `yang-${index}` }}>
                <IconWrapper>{element.icon1}</IconWrapper>
                <RightSide>
                  {element.yang.map((el, indexY) => (
                    <CustomWrapper key={indexY}>{el}</CustomWrapper>
                  ))}
                  {index === 1 && (
                    <SocialIcons
                      faceSize="2.2rem"
                      instSize="2.5rem"
                      marginTopBottom="0px"
                    />
                  )}
                </RightSide>
              </Yang>
            )}
          </React.Fragment>
        ))}
      </Grid>

      <UnderFoot>
        <AllRights>
          <span>WINDOOR</span> &#169; wszelke prawa zasrzeżone
        </AllRights>
      </UnderFoot>
    </MainWrapper>
  );
}

export default Footer;

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 150px;
  position: relative;
  color: white;
  border-top: 25px solid #ffdd00;
  background: linear-gradient(to left, #0d3bbc, #1fa4df);
  @media screen and (max-width: 900px) {
    margin-top: 50px;
  }
`;

const Grid = styled.div`
  display: grid;
  margin-top: 40px;
  grid-template-columns: (auto-fit, 3);
  grid-template-rows: (auto-fit, 3);
  grid-auto-rows: auto;
  font-size: clamp(1.6rem, 1.4rem, 2rem);
  grid-template-areas:
    "title-0 title-1 title-2"
    "yin-0  yin-1  yin-2 "
    "yang-0  yang-1  yin-2 ";
  @media screen and (max-width: 1150px) {
    font-size: clamp(0.9rem, 1.1rem, 1.5rem);
  }
  @media screen and (max-width: 1000px) {
    grid-template-areas:
      "title-0 title-1 "
      "yin-0  yin-1  "
      "yang-0  yang-1"
      "title-2 title-2"
      "yin-2 yin-2 ";
  }
  @media screen and (max-width: 580px) {
    grid-template-areas:
      "title-0"
      "yin-0"
      "yang-0"
      "title-1 "
      "yin-1 "
      "yang-1"
      "title-2"
      "yin-2"
      "yang-2";
  }

  @media screen and (max-width: 580px) {
    font-size: 1.4rem;
  }
`;

const UnderFoot = styled.div`
  display: flex;
  justify-content: space-between;
  color: #a7ada9;
  padding: 10px 100px 5px 40px;
  font-size: 1.3rem;
  background: rgba(255, 255, 255, 0.1);
  @media screen and (max-width: 1150px) {
    font-size: clamp(0.9rem, 1.1rem, 1.5rem);
  }
  @media screen and (max-width: 770px) {
    flex-direction: column;
    padding: 15px 10px;
  }
`;

const AllRights = styled.div`
  color: #dde0de;
  font-size: 1.1rem;
  span {
    font-size: 1.5rem;
    font-family: "Myriad Pro" sans-serif;
  }
  @media screen and (max-width: 1150px) {
    font-size: 0.9rem;
    span {
      font-size: clamp(0.9rem, 1.2rem, 1.5rem);
    }
  }
`;

const RightSide = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  flex: 1;
`;

const TextWrapper = styled.div`
  margin-left: 5%;

  @media screen and (min-width: 800px) {
    padding-bottom: 100px;
  }
`;
const IconWrapper = styled.div`
  margin-right: 10px;
  filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.3));
`;

const Cell = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 20px 10px 15px 20px;
  :hover {
    background: rgba(255, 255, 255, 0.1);
  }
`;

const Yin = styled(Cell)`
  border-right: 1px solid white;
  border-bottom: 1px solid white;

  :last-of-type {
    display: block;
    font-size: 1.2rem;
    column-count: 2;
    column-gap: 10px;
    border-right: none;
    @media screen and (max-width: 1150px) {
      font-size: clamp(0.9rem, 1rem, 1.5rem);
    }
    @media screen and (max-width: 580px) {
      font-size: 1.1rem;
    }
    @media screen and (max-width: 400px) {
      font-size: 1rem;
    }
    @media screen and (max-width: 580px) {
      column-count: 1;
    }
  }
  :first-of-type {
    border-left: none;
    background: red;
  }

  @media screen and (max-width: 1000px) {
    :nth-of-type(2n -3) {
      border-right: none;
    }
  }

  @media screen and (max-width: 580px) {
    border-right: none;
  }
`;

const Yang = styled(Cell)`
  border: 1px solid white;
  border-left: none;
  @media screen and (max-width: 1000px) {
    :nth-of-type(2n -2) {
      border-right: none;
    }
  }

  @media screen and (max-width: 580px) {
    border-right: none;
    font-size: 1.2rem;
  }
`;

const CustomWrapper = styled.div`
  margin-top: auto;
  margin-bottom: auto;
`;

const Title = styled.div`
  font-size: 1.9rem;
  border: 1px solid white;
  padding: 20px;
  font-weight: 500;
  border-bottom: 5px solid yellow;
  margin: 0;
  text-decoration: 3px solid black;
  background: rgba(255, 255, 255, 0.1);
  transition: 1s;
  :first-of-type {
    border-left: none;
  }

  :hover {
    + ${Yin} {
      + ${Yang} {
        background: rgba(255, 255, 255, 0.1);
      }
      background: rgba(255, 255, 255, 0.1);
    }
    + ${Yang} {
    }
    background: rgba(255, 255, 255, 0.1);
  }

  :nth-of-type(2n -1) {
    border-right: none;
  }

  @media screen and (max-width: 1150px) {
    font-size: clamp(0.9rem, 1.3rem, 1.5rem);
  }

  @media screen and (max-width: 1000px) {
    :nth-of-type(2n -2) {
      border-right: none;
    }

    :nth-of-type(2n -1) {
      border-left: none;
    }
  }

  @media screen and (max-width: 580px) {
    border-left: none;
    font-size: 1.6rem;
  }
`;

const A = styled.a`
  text-decoration: none;
  color: #a7ada9;

  :nth-of-type(2) {
    margin-left: 10px;
  }
  :hover {
    color: #cacaca;
  }
`;
