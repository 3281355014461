import React from "react";
import styled from "styled-components";
import { BsFacebook } from "react-icons/bs";
import { RiInstagramFill } from "react-icons/ri";
import { social } from "../../data/ConstantData";

function SocialIcons({ faceSize, instSize, marginTopBottom }) {
  return (
    <SocialContainer margin={marginTopBottom}>
      <A
        fontSize={faceSize}
        href={social.facebook_url}
        target="__blank"
        rel="noopener noreferrer"
      >
        <BsFacebook />
      </A>
      {/* <A
        fontSize={instSize}
        href={social.instagram_url}
        marginTopBottom={marginTopBottom}
        target="__blank"
        rel="noopener noreferrer"
      >
        <RiInstagramFill />
      </A> */}
    </SocialContainer>
  );
}

export default SocialIcons;

const SocialContainer = styled.div`
  width: 90px;
  display: flex;
  margin: ${(props) => props.margin || "40px"};
  @media screen and (max-width: 1000px) {
    width: 80px;
    margin-top: 15px;
  }
`;

const A = styled.a`
  text-decoration: none;
  color: white;
  font-size: ${(props) => props.fontSize || "3.6rem"};
  filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.3));
  :nth-of-type(1) {
    :hover {
      color: #409dea;
    }
  }
  :nth-of-type(2) {
    :hover {
      color: #da52cf;
    }
  }

  @media screen and (max-width: 1000px) {
    font-size: 1.9rem;
    :nth-of-type(2) {
      font-size: 2.1rem;
    }
  }
`;
