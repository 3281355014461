import React from "react";
import styled from "styled-components";

function ButtonEl({ children, type, title, disabled, id, dataTip, onClick }) {
  return (
    <Button
      type={type}
      title={title}
      onClick={onClick}
      disabled={disabled}
      id={id}
      dataHtml={true}
      data-tip={dataTip}
    >
      {children}
    </Button>
  );
}

export default ButtonEl;

const Button = styled.button`
  font-size: 1.8rem;
  width: 100%;
  height: 80px;
  display: flex;
  text-transform: uppercase;
  font-weight: 600;
  border-radius: 40px;
  justify-content: center;
  align-items: center;
  background: linear-gradient(#ffe11c, #ffde06);
  transition: 0.3s;
  border: none;
  color: rgba(0, 0, 0, 0.7);
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.3);

  :hover {
    cursor: pointer;
    background: linear-gradient(#ffe852, #ffea07);
    color: #3f00b6;
    background: linear-gradient(#88e42c, #56ab02);
    color: rgba(0, 0, 0, 0.7);
  }
  :active {
    scale: 0.99;
  }

  :disabled {
    cursor: no-drop;
    :hover {
      width: 100%;
      color: #e5e5e5;
      background: linear-gradient(#c1c1c0, #676761);
      transition: 0.2s;
    }
  }

  @media screen and (max-width: 800px) {
    font-size: 1.4rem;
    height: 67px;
  }
`;
