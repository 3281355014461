import React from "react";
import AOSanimation from "./AOSanimation";
import TransparentContainer from "./TransparentContainer/TransparentContainer";
import { AboutCompanyArr } from "../data/AboutCompanyData";
import { myObjects } from "../data/TextData";
import styled from "styled-components";

function AboutCompany() {
  const { image, title, desc } = myObjects.aboutCompany;

  return (
    <TransparentContainer
      id="aboutCompany"
      image={image}
      title={title}
      desc={desc}
    >
      <H3> Umów się na wycenę zobacz jakie to proste:</H3>
      <ImageContainer>
        {AboutCompanyArr.map((el, index) => (
          <SingleImage key={index}>
            <AOSanimation aos="fade-up" aosDelay={50}>
              {el.image}
              <p>{el.title}</p>
            </AOSanimation>
          </SingleImage>
        ))}
      </ImageContainer>
    </TransparentContainer>
  );
}

export default AboutCompany;

const H3 = styled.h3`
  text-align: center;
  font-weight: 400;
  font-size: clamp(1rem, 2.5rem, 3rem);
  @media screen and (max-width: 800px) {
    font-size: 2rem;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-bottom: 25px;
  justify-content: center;
  position: relative;
`;

const SingleImage = styled.div`
  grid-area: one;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  padding: 10px;
  margin: 10px;
  display: flex;
  font-size: clamp(1.2rem, 1.5rem, 3rem);
  text-align: center;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 195px;
  font-weight: 500;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  @media screen and (max-width: 910px) {
    width: 165px;
  }

  @media screen and (max-width: 650px) {
    width: 135px;
  }

  @media screen and (max-width: 570px) {
    width: 80%;
  }
`;
