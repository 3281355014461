import React, { useState } from "react";
import styled from "styled-components";
import CloseButton from "react-bootstrap/CloseButton";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "./Form/Form";

function ModalForm(props) {
  return (
    <Modal
      animation={false}
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <ModalContent>
        <Modal.Header>
          <CloseButton variant="white" onClick={props.onHide} />
        </Modal.Header>
        <Modal.Body>
          <Form />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="warning" onClick={props.onHide}>
            Zamknij
          </Button>
        </Modal.Footer>
      </ModalContent>
    </Modal>
  );
}

export default ModalForm;

const ModalContent = styled.div`
  background: linear-gradient(to left, #0d3bbc, #1fa4df);
`;

export { ModalContent };
