import React from "react";
import TransparentContainer from "../TransparentContainer/TransparentContainer";
import CircleAnimation from "../CircleAnimation/CircleAnimation";
import styled from "styled-components";
import { myObjects } from "../../data/TextData";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import AOSanimation from "../AOSanimation";

function Stats() {
  const { width } = useWindowDimensions();

  const SmallCircle = {
    size: 240,
    strokeWidth: 25,
    fontSize: 55,
  };

  const BigCircle = {
    size: 300,
    strokeWidth: 35,
    fontSize: 65,
  };

  const MediumCircle = {
    size: 280,
    strokeWidth: 25,
    fontSize: 65,
  };

  function useCirce() {
    if (width < 1250 && width > 800) {
      return MediumCircle;
    } else if (width < 800) {
      return SmallCircle;
    } else return BigCircle;
  }

  return (
    <TransparentContainer title={myObjects.stats.title} id="stats" button>
      <Wrapper>
        <AOSanimation aos="fade-up" aosDelay="50">
          <CircleAnimation
            size={useCirce().size}
            strokeWidth={useCirce().strokeWidth}
            fontSize={useCirce().fontSize}
            percentage={25}
            color="yellow"
          >
            {" "}
            Więcej ciepła przy
            <br /> wymianie okien i drzwi{" "}
          </CircleAnimation>{" "}
        </AOSanimation>
        <AOSanimation aos="fade-up" aosDelay="50">
          <CircleAnimation
            size={useCirce().size}
            strokeWidth={useCirce().strokeWidth}
            fontSize={useCirce().fontSize}
            percentage={30}
            color="yellow"
          >
            {" "}
            Okna i drzwi stanowiące
            <br /> powierzchnię budynków{" "}
          </CircleAnimation>{" "}
        </AOSanimation>

        <AOSanimation aos="fade-up" aosDelay="50">
          <CircleAnimation
            size={useCirce().size}
            strokeWidth={useCirce().strokeWidth}
            fontSize={useCirce().fontSize}
            percentage={100}
            color="yellow"
          >
            {" "}
            Zadowolonych <br /> klientów{" "}
          </CircleAnimation>{" "}
        </AOSanimation>
      </Wrapper>
    </TransparentContainer>
  );
}

export default Stats;

const Wrapper = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: space-around;

  @media screen and (max-width: 1250px) {
    flex-direction: column;
  }
`;
