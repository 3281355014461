import React, { useState } from "react";
import styled from "styled-components";
import Hamburger from "hamburger-react";
import { menuData } from "../../data/MenuData";
import { HashLink as Link } from "react-router-hash-link";
import { BrowserRouter as Router } from "react-router-dom";
import { social } from "../../data/ConstantData";
import SprzedamSzkodeText from "../SprzedamSzkodeText/SprzedamSzkodeText";
import SocialIcons from "../SocialIcons/SocialIcons";
import Logo from "../../assets/images/Hero/logo.svg";

function BurgerMenu() {
  const [isOpen, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!isOpen);
  };

  return (
    <>
      <BurgerWrapper open={isOpen}>
        <Hamburger toggled={isOpen} toggle={setOpen} size={36} distance="sm" />
      </BurgerWrapper>
      <MobileMenu open={isOpen}>
        <MobileContainer>
          <ScrollContainer>
            <TextWrapper>
              <Logo />
            </TextWrapper>
            <Router>
              <LinkContainer>
                {menuData.map((item, index) => (
                  <MobileLink
                    smooth
                    to={item.link}
                    key={index}
                    onClick={handleClick}
                  >
                    {item.title}
                  </MobileLink>
                ))}
              </LinkContainer>
            </Router>
            <SocialWrapper>
              <SocialIcons faceSize="5rem" instSize="2.8rem" />
            </SocialWrapper>
          </ScrollContainer>
        </MobileContainer>
      </MobileMenu>
    </>
  );
}

export default BurgerMenu;

const SocialWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const MobileMenu = styled.div`
  display: none;
  display: ${({ open }) => (open ? "flex" : "none")};
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  text-align: center;
  height: 100vh;
  background: linear-gradient(#0d3bbc, #1fa4df);
  transition: all 0.35s;
  overflow: hidden;
  z-index: 250;
  padding-top: 50px;
  margin-left: 50px;
  opacity: ${({ open }) => (open ? 1 : 0)};
  @media screen and (min-width: 1240px) {
    display: none;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  width: 80%;
  margin: auto;
`;

const MobileContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  -webkit-overflow-scrolling: touch; // mobile safari
  justify-content: space-around;
  overflow-y: scroll;
  align-items: center;
  justify-items: center;
  align-items: center;
  width: 100%;
  z-index: 2;
`;
const ScrollContainer = styled.div`
  height: 100%;
  width: 100%;
  text-align: center;
`;

const LinkContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  font-size: 1.7rem;
  text-decoration: none;
  width: 100%;
`;

const MobileLink = styled(Link)`
  text-decoration: none;
  font-size: 1.8rem;
  border-bottom: 1px solid white;
  text-align: center;
  width: 100%;
  color: white;
  padding: 12px;
  :first-child {
    border-top: 1px solid white;
  }

  :hover {
    color: #ffdd00;
  }
  @media screen and (min-width: 500px) {
    padding: 18px;
    font-size: 2rem;
  }
`;
const BurgerWrapper = styled.div`
  display: none;
  color: #fff;
  @media screen and (max-width: 1240px) {
    display: block;
    top: 15px;
    right: 15px;
    z-index: 300;
    position: fixed;
    font-size: 3rem;
    background: rgba(0, 0, 0, 0.1);
    -webkit-tap-highlight-color: transparent;
  }
`;
