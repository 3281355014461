import React from "react";
import { ImMobile } from "react-icons/im";
import { IoIosMail } from "react-icons/io";

export const social = {
  facebook_url: "https://www.facebook.com/windooroknaidrzwi",
  instagram_url: "https://www.instagram.com/sprzedam_szkode/",
};

export const contact = [
  {
    href: "tel:+48609011716",
    text: "+48 609 011 716",
    icon: <ImMobile style={{ fontSize: "2.5rem" }} />,
  },
  {
    href: "mailto: tadeusz.windoor@gmail.com",
    text: "tadeusz.windoor@gmail.com",
    icon: <IoIosMail style={{ fontSize: "2.6rem" }} />,
  },
];
