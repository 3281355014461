import React from "react";
import TransparentContainer from "./TransparentContainer/TransparentContainer";
import { Gallery } from "gatsby-theme-gallery";
function Realizations() {
  return (
    <>
      <TransparentContainer
        id="realizations"
        title=" Przykładowe Realizacje"
      ></TransparentContainer>
      <Gallery galleryPath="content/gallery" />
    </>
  );
}

export default Realizations;
