import { createGlobalStyle } from "styled-components";
/*
Main colors: 
Dark blue right side : #0D3BBC
Light blue left side : #1FA4DF
Hover grey : #c0c0c0


*/

const darkBlue = "#0D3BBC";
const lightBlue = "#1FA4DF";

const GlobalStyle = createGlobalStyle`

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
    background-color: transparent;
  }

  body{
    background: linear-gradient(to left, #0D3BBC, #1FA4DF );
    color: white;
 
  }
`;

export default GlobalStyle;
