import React from "react";

import styled from "styled-components";
import { AiFillHome } from "react-icons/ai";
import { BsInfoLg } from "react-icons/bs";
import { contact } from "./ConstantData";

const A = styled.a`
  text-decoration: none;
  color: inherit;
  margin-bottom: 25px;
  :hover {
    color: #c0c0c0;
    color: #02ff02;
  }
`;

const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Ico = styled.div`
  margin-right: 10px;
`;

const contactData = contact.map((el) => (
  <A href={el.href}>
    <LinkWrapper>
      <Ico>{el.icon}</Ico>
      <div>{el.text}</div>
    </LinkWrapper>
  </A>
));

const province = [
  `• Kłodzko`,
  `• Kudowa-Zdrój`,
  `• Bolesławiec`,
  `• Wrocław`,
  `• Bielawa`,
  `• Wałbrzych`,
  `• Legnica`,
  `• Lubin`,
  `• Zgorzelec`,
  `• Jelenia Góra`,
  `• Głogów`,
  `• Świdnica`,
  `• Dzierżonów`,
  `• Strzegom`,
  `• Kamienna Góra`,
  `• Oleśnica`,
  `• Oława`,
];

export const footerData = [
  {
    title: "WINDOOR",
    yin: [`Golfowa 24`, `57-330 Szczytna`],
    icon: <AiFillHome style={{ fontSize: "2.5rem" }} />,
    icon1: <BsInfoLg style={{ fontSize: "2.5rem" }} />,
    yang: [`NIP: 8811448810`, `REGON: 521423556`],
  },
  {
    title: "Kontakt",
    yin: [contactData[0]],
    yang: [contactData[1]],
  },
  {
    title: "Obszar działania firmy",
    yin: province,
  },
];
