import * as React from "react";
import NoSSR from "react-no-ssr";
import Hero from "../components/Hero";
import Header from "../components/Header";
import Stats from "../components/Stats/Stats";
import Footer from "../components/Footer";
import ContentWrapper from "../components/Form/ContentWrapper";
import GlobalStyle from "../components/styles/GlobalStyles";
import "./index.scss";
import AboutCompany from "../components/AboutCompany";
import Offer from "../components/Offer";
import Realizations from "../components/Realizations";
import ScrollButton from "../components/ScrollUpButton";
import CookieInfo from "../components/CookieNotice/CookieNotice";

// markup
const IndexPage = () => {
  return (
    <NoSSR>
      <CookieInfo />
      <GlobalStyle />
      <Header />
      <Hero />
      <ScrollButton />
      <ContentWrapper>
        <AboutCompany />
        <Offer />
        <Realizations />
      </ContentWrapper>
      <ContentWrapper>
        <Stats />
      </ContentWrapper>
      <Footer />
    </NoSSR>
  );
};

export default IndexPage;
