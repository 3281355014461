import React from "react";
import { CookieNotice } from "gatsby-cookie-notice";
import * as styles from "./CookieNotice.module.scss";
import { StaticImage } from "gatsby-plugin-image";
import styled from "styled-components";

function CookieInfo() {
  return (
    <CookieNotice
      backgroundClasses={styles.background}
      backgroundWrapperClasses={styles.backgroundWrapper}
      acceptButtonText="Akceptuję"
      declineButton={false}
      acceptButtonClasses={`${styles.buttonsClass} ${styles.acceptBtn}`}
      personalizeButtonText={"Dostosuj"}
      personalizeButtonClasses={styles.buttonsClass}
      personalizeValidationText="Zapisz"
      cookies={[
        {
          name: "necessary",
          editable: false,
          default: true,
          title: "Podstawowe",
          text: "Podstawowe ciasteczka są niezbędne do prawidłowego funkcjonowania strony. Stronie nie może funkcjinować właściwie bez nich.",
        },
        {
          name: "gatsby-gdpr-google-analytics",
          editable: true,
          default: true,
          title: "Google Analytics",
          text: "Google Analytics jest to narzędzie do analizy statysk, które pomaga mierzyć oglądalność w wyszukiwarce Google.",
        },
        {
          name: "gatsby-gdpr-facebook-pixel",
          editable: true,
          default: true,
          title: "Facebook Pixel",
          text: "Facebook Pixel jest to narzędzie do analizy statysk, które pomaga mierzyć oglądalność na stronie Facebook.",
        },
      ]}
    >
      <Container>
        <ImageWrapper>
          <StaticImage src="../../assets/images/cookies.png" />
        </ImageWrapper>
        <P>Akceptuje politykę prywatnosci plików cookies</P>
      </Container>
    </CookieNotice>
  );
}

export default CookieInfo;

const A = styled.a`
  color: white;
  :hover {
    color: white;
  }
`;

const P = styled.p`
  font-size: 1rem;
`;

const ImageWrapper = styled.div`
  padding: 10px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
